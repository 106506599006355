import { FC, ReactNode, useEffect } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa6";
import PageHeader from "../components/commons/page-header";
import { BiMenu } from "react-icons/bi";
import mandilogo from "../assets/images/mandi-logo.png";
import { useState } from "react";
import { Button } from "../components/ui";
import logout from "../assets/images/logout.svg";
import { useDispatch } from "react-redux";
import { handelLogout } from "../reducers/authSlice";
import { RiGroupFill } from "react-icons/ri";
import trades from "../assets/images/traderss.png";

const PageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("customers");
  const location = useLocation();
  const onClickTab = (tab: any) => {
    setActiveTab(tab);
  };
  const logOut = () => {
    localStorage.clear();
    dispatch(handelLogout(null));
    navigate("/");
  };

  useEffect(() => {
    console.log(location.pathname);
  
    if (location.pathname === "/trades") {
      setActiveTab("trades");
    } else if (location.pathname === "/my-profile") {
      setActiveTab("MyProfile");
    } else if (matchPath("/trade-detail/:traderId/:tradeId", location.pathname)) {
      setActiveTab("trades");
    } else {
      setActiveTab("customers");
    }
  }, [location.pathname]);

  const titles: Record<string, string> = {
    trades: "Trades",
    MyProfile: "My Profile",
    customers: "Customers",
    DailyPrice: "Daily Price",
    Market: "Market",
  };
  // Assume activeTab is a state or prop that determines the current tab
  const currentTitle = titles[activeTab] || "Default Title";

  return (
    <div className="flex">
      <div
        className="h-screen overflow-auto bg-white shadow-md font-semibold flex-shrink-0 relative"
        style={{ width: "220px", borderRight: "1px solid #e4e4e4" }}
      >
        <div className="shadow-md gap-4 flex items-center px-4">
          <BiMenu size={25} style={{ color: "green" }} />
          <img
            src={mandilogo}
            className="w-24 ml-2"
            style={{ height: "60px", width: "50px" }}
            alt="Mandi Logo"
          />
        </div>

        <ul className="pl-4 py-4 ">
          {/* customer starts here */}
          <li
            className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
              activeTab == "customers" ? "bg-primary" : ""
            }`}
            style={{ borderRadius: "30px 0 0 30px" }}
            onClick={() => {
              navigate("/customers");
              onClickTab("customers");
            }}
          >
            <div
              className={`w-7 h-7 br-100 flex justify-center items-center ${
                activeTab == "customers" ? "bg-yellow" : "bg-gray-dark"
              }`}
            >
              <RiGroupFill className="w-4 h-4 color-primary" />
            </div>
            <p
              className={`text-sm font-semibold ${
                activeTab == "customers" ? "color-yellow" : ""
              }`}
            >
              Customers
            </p>
          </li>

          {/* trade starts here */}
          <li
            className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
              activeTab == "trades" ? "bg-primary" : ""
            }`}
            style={{ borderRadius: "30px 0 0 30px" }}
            onClick={() => {
              navigate("/trades");
              onClickTab("trades");
            }}
          >
            <div
              className={`w-7 h-7 br-100 flex justify-center items-center ${
                activeTab == "trades" ? "bg-yellow" : "bg-gray-dark"
              }`}
            >
              {/* <AiFillHome className="w-4 h-4 color-primary" /> */}
              <img
                src={trades}
                alt="trades"
                className="w-8 h-8 color-primary"
              />
            </div>
            <p
              className={`text-sm font-semibold ${
                activeTab == "trades" ? "color-yellow" : ""
              }`}
            >
              Trades
            </p>
          </li>
          {/* my-profile starts here */}
          <li
            className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
              activeTab == "MyProfile" ? "bg-primary" : ""
            }`}
            style={{ borderRadius: "30px 0 0 30px" }}
            onClick={() => {
              navigate("/my-profile");
              onClickTab("MyProfile");
            }}
          >
            <div
              className={`w-7 h-7 br-100 flex justify-center items-center ${
                activeTab == "MyProfile" ? "bg-yellow" : "bg-gray-dark"
              }`}
            >
              <FaUser className="w-4 h-4 color-primary" />
            </div>
            <p
              className={`text-sm font-semibold ${
                activeTab == "MyProfile" ? "color-yellow" : ""
              }`}
            >
              {" "}
              My Profile
            </p>
          </li>

          {/* daily price starts */}
          {/* <li
            className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
              activeTab == "DailyPrice" ? "bg-primary" : ""
                      }`}
            style={{ borderRadius: "30px 0 0 30px" }}
            onClick={() => {
              navigate("/DailyPrice");
              onClickTab("DailyPrice");
            }}
          >
            <div
              className={`w-7 h-7 br-100 flex justify-center items-center ${
                activeTab == "DailyPrice" ? "bg-yellow" : "bg-gray-dark"
              }`}
            >
              <FaRupeeSign className="w-4 h-4 color-primary" />
            </div>
            <p
              className={`text-sm font-semibold ${
                activeTab == "DailyPrice" ? "color-yellow" : "" 
              }`}
            >
              {" "}
             Daily Price
            </p>
          </li> */}

          {/* market starts here */}
          {/* <li
            className={`flex items-center gap-3 text-base font-normal p-3 cursor-pointer mb-4  ${
              activeTab == "Market" ? "bg-primary" : ""
                      }`}
            style={{ borderRadius: "30px 0 0 30px" }}
            onClick={() => {
              navigate("/Market");
              onClickTab("Market");
            }}
          >
            <div
              className={`w-7 h-7 br-100 flex justify-center items-center ${
                activeTab == "Market" ? "bg-yellow" : "bg-gray-dark"
              }`}
            >
              <img src={market} className="w-7 h-7 color-primary" />
            </div>
            <p
              className={`text-sm font-semibold ${
                activeTab == "Market" ? "color-yellow" : "" 
              }`}
            >
              {" "}
             Market
            </p>
          </li> */}
          {/* logout button starts here */}
          <li>
            <div className="fixed bottom-10 left-18">
              <Button
                className="mx-auto"
                shape="round"
                variant="red"
                onClick={() => {
                  logOut();
                }}
              >
                <img src={logout} className="pr-2" />
                Logout
              </Button>
            </div>
          </li>
        </ul>
      </div>
      {/* page headder lo trade open chestey trade ani my profile open chestey my profile ani ravadaniki idey code */}
      <div className="ml-auto grow h-screen overflow-auto">
        <PageHeader title={currentTitle} showTitle={true} showUserInfo={true} />
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 3.8rem)" }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
